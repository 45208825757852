
#team-alt .team-image > div {
    position:relative;
    height:100%;
    overflow:hidden;
}
#team-alt .team-image > div > img {
    transition: opacity 0.5s;
    height:100%;
    width:auto;
    min-width:100%;
    max-width:none;
    margin-left:50%;
    transform: translate(-50%, 0);
}
#team-alt .team-image > div > img:nth-child(2) {
    position:absolute;
    top:0px;
    left:0px;
}
#team-alt .team-image:hover > div > img:nth-child(2) {
    opacity: 0;
}
#team-alt .heading-block::after {
    margin-top:20px;
}
#team-alt .team-description h4 {
    margin-bottom: 5px;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
}


